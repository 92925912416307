let AbstractComponent = require('../../AbstractComponent');
const {
  createElementWithAttribute,
  getDataFromFirebase,
} = require('../../../utils/functions');
const shortid = require('shortid');

class AssignmentView extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;
    this.uid = localStorage.getItem('uid');
    this.currentClassId = null;
    this.assignmentArray = [];
    this.tableHeaderContent = '';

    this.init();
  }

  init() {
    this.createClassesSelector();
    this.createViewHeader();
    this.createProgressTable();
  }

  createViewHeader() {
    const backToAssignmentViewBtn = createElementWithAttribute('button', {
      class: 'outline-btn',
    });

    backToAssignmentViewBtn.innerText = 'Back';
    backToAssignmentViewBtn.addEventListener('click', () => {
      document.getElementById('assignment-view').classList.remove('hide');
      document.getElementById('assignment-progress-view').classList.add('hide');
    });

    const assignmentListTitle = createElementWithAttribute('h3', {
      class: 'assignment-list-title',
    });
    assignmentListTitle.innerText = 'Student Progress';

    const headerContainer = createElementWithAttribute('div', {
      class: 'assignment-progress-container-header',
    });
    headerContainer.appendChild(assignmentListTitle);
    headerContainer.appendChild(backToAssignmentViewBtn);

    this.parent.appendChild(headerContainer);

    //View Student Progress button
    const viewProgressBtn = createElementWithAttribute('button', {
      id: 'view-assignment-progress-btn',
      class: 'view-assignment-btn',
    });

    viewProgressBtn.innerText = 'View Student Progress';
    viewProgressBtn.addEventListener('click', () => {
      //update assignment list in progress view
      this.getAssignmentList(this.currentClassId).then(() => {
        //Get student progress
        this.getStudentList(this.currentClassId);
      });

      //Show the assignment progress view
      document.getElementById('assignment-view').classList.add('hide');
      document
        .getElementById('assignment-progress-view')
        .classList.remove('hide');
    });

    document.getElementById('assignment-actions').appendChild(viewProgressBtn);
  }

  createClassesSelector() {
    const selectorContainer = createElementWithAttribute('div', {
      class: 'classes-selector-container',
    });
    selectorContainer.innerHTML = `<select name="select-class-name" id="assignment-progress-select-class-name" class="form-control">
        <option>No class found, please create a class in Manage menu</option>
        </select>`;
    this.parent.appendChild(selectorContainer);

    //Update Class list
    let that = this;

    const selectClassContainerAssignment = document.getElementById(
      'assignment-progress-select-class-name'
    );

    //Fetch Class list from firebase
    getDataFromFirebase('/classes', this.uid).then((result) => {
      if (result.status === 'OK') {
        const classInfo = result.data;

        //Output HTML element for select list
        let selectOptions = '';

        for (let [key, value] of Object.entries(classInfo)) {
          let classId = key;
          let className = value.name;
          selectOptions += `<option value=${classId} >${className}</option>`;
        }

        //Init currentClassId equal the first class id
        that.currentClassId = Object.keys(classInfo)[0];
        that.getAssignmentList(that.currentClassId);

        selectClassContainerAssignment.innerHTML = selectOptions;

        selectClassContainerAssignment.addEventListener('change', (e) => {
          that.currentClassId = selectClassContainerAssignment.value;
          //Update assignment list
          that.getAssignmentList(that.currentClassId).then(() => {
            //Update student list
            that.getStudentList(that.currentClassId);
          });
        });

        //Get assignment list first then get student list
        that.getAssignmentList(that.currentClassId).then(() => {
          //Get the first class student progress when init
          that.getStudentList(selectClassContainerAssignment.value);
        });
      }
    });
  }

  async getAssignmentList(classId) {
    //Fetch Assignment list from firebase
    await getDataFromFirebase('classes/' + classId + '/assignment', this.uid)
      .then((result) => {
        if (result.status === 'OK') {
          this.assignmentArray = Object.entries(result.data);
          this.tableHeaderContent = '<tr><th></th>';
          for (let [key, value] of Object.entries(result.data)) {
            this.tableHeaderContent += `<th title="${value.name}">${value.name}</th>`;
          }

          this.tableHeaderContent += '</tr>';
          document.getElementById(
            'assignment-progress-thead'
          ).innerHTML = this.tableHeaderContent;
        } else {
          //No assignment find
          this.assignmentArray = [];
          document.getElementById('assignment-progress-thead').innerHTML = '';
        }
      })
      .catch((err) => {
        //No assignment find
        this.assignmentArray = [];
        document.getElementById('assignment-progress-thead').innerHTML =
          'No Assignment found';
      });
  }

  //Create student progress table
  createProgressTable() {
    const tableWrapper = createElementWithAttribute('div', {
      id: 'assignment-progress-table-wrapper',
    });

    const tableScroll = createElementWithAttribute('div', {
      id: 'assignment-progress-table-scroll',
      style: 'display:none',
    });

    const studentTable = createElementWithAttribute('table', {
      class: 'student-progress-table table',
    });

    const tableHeader = createElementWithAttribute('thead', {
      id: 'assignment-progress-thead',
    });

    const tableBody = createElementWithAttribute('tbody', {
      id: 'assignment-progress-tbody',
    });

    const errorMessage = createElementWithAttribute('p', {
      id: 'no-student-progress-message',
      style: 'display:none',
    });
    errorMessage.innerText =
      'Currently, there is no student in this class, please add student in Manage > Profiles';

    studentTable.appendChild(tableHeader);
    studentTable.appendChild(tableBody);
    tableScroll.appendChild(studentTable);
    tableWrapper.appendChild(tableScroll);
    tableWrapper.appendChild(errorMessage);

    this.parent.appendChild(tableWrapper);
  }

  getStudentList(classId) {
    let that = this;

    let userId = firebase.auth().currentUser.uid;
    let studentListRefProgress = database.ref(
      '/profiles/' + userId + '/classes/' + classId + '/student'
    );
    studentListRefProgress.on('value', function (snapshot) {
      let students = snapshot.val();
      if (students) {
        const studentInfoDataset = Object.entries(students);

        const studentInfo = studentInfoDataset.map((student) => {
          return {
            studentId: student[0],
            name: student[1].name,
            assignments: student[1].assignments
              ? Object.entries(student[1].assignments)
              : [],
          };
        });

        let output = '';
        studentInfo.forEach((student) => {
          let assignmentsLink = '';

          that.assignmentArray.forEach((assignment) => {
            let isFindAssignment = false;
            student.assignments.forEach((studentAssignment) => {
              if (studentAssignment[0] === assignment[0]) {
                assignmentsLink += `<td><a target="blank" title="${student.name} assignment world" class="assignment-link" id="${student.studentId}-${assignment[0]}" href="${mainApp}/?origin=${classroomOrigin.origin}&class=${that.currentClassId}&student=${student.studentId}&name=${student.name}&assignment=${assignment[0]}&role=teacher&id=${studentAssignment[1].sid}"></a></td>`;

                isFindAssignment = true;
              }
            });
            if (!isFindAssignment)
              assignmentsLink += `<td><a title="No submission yet" class="assignment-link not-submit" href="#"></a></td>`;
          });

          output += `<tr class="assignment-progress-tr" id="assignment-progress-${student.studentId}"><td>${student.name}</td>${assignmentsLink}</tr>`;
        });

        document.getElementById('assignment-progress-tbody').innerHTML = output;
        document.getElementById(
          'assignment-progress-table-scroll'
        ).style.display = 'block';

        document.getElementById('no-student-progress-message').style.display =
          'none';

        // Generate assignment progress link
      } else {
        //Nothing find, empty student progress table
        document.getElementById(
          'assignment-progress-table-scroll'
        ).style.display = 'none';
        document.getElementById('no-student-progress-message').style.display =
          'block';
      }
    });
  }
}

module.exports = AssignmentView;
