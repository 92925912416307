let AbstractComponent = require('../AbstractComponent.js');
const { createElementWithAttribute } = require('../../utils/functions');

class Progress extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;
    this.classId = '';
    this.className = '';
    this.studentList = [];
    this.menus = [];
    this.error = false;
    this.init();
  }

  init() {
    this.createClassesSelector();
    this.createProgressLegend();
    this.createProgressTable();
  }

  createClassesSelector() {
    const selectorContainer = document.createElement('div');
    selectorContainer.className = 'classes-selector-container';
    selectorContainer.innerHTML = `<select name="select-class-name" id="progress-select-class-name" class="form-control">
      <option>No class found, please create a class in Manage menu</option>
      </select>`;
    this.parent.appendChild(selectorContainer);

    //Update Class list
    let userId = firebase.auth().currentUser.uid;
    let that = this;

    const selectClassContainerProgress = document.getElementById(
      'progress-select-class-name'
    );

    //Fetch Class list from firebase
    database
      .ref('/profiles/' + userId + '/classes')
      .once('value')
      .then(function (snapshot) {
        let classList = snapshot.val();
        if (classList) {
          const classInfo = Object.entries(classList);

          //Output HTML element for select list
          let selectOptions = '';

          classInfo.forEach((singleClass) => {
            that.classId = singleClass[0];
            that.className = singleClass[1].name;

            selectOptions += `<option value=${that.classId} >${that.className}</option>`;
          });

          selectClassContainerProgress.innerHTML = selectOptions;

          selectClassContainerProgress.addEventListener('change', (e) => {
            const classId = selectClassContainerProgress.value;
            //Update student list
            that.getStudentList(classId);
          });

          //Get the first class student progress when init
          that.getStudentList(selectClassContainerProgress.value);
        }
      });
  }

  //Create student progress table
  createProgressTable() {
    const tableWrapper = createElementWithAttribute('div', {
      id: 'table-wrapper',
    });

    const tableScroll = createElementWithAttribute('div', {
      id: 'table-scroll',
      style: 'display:none',
    });

    const studentTable = createElementWithAttribute('table', {
      class: 'student-progress-table table',
    });

    let tableHeader = `
           <thead>
           <tr>
           <th scope="col"></th>
           <th colspan="3">BASIC</th>
           <th colspan="3">INTERMEDIATE</th>
           <th colspan="3">ADVANCED</th>
           </tr>
           <tr>
             <th scope="col"></th>
             <th scope="col">Module 1</th>
             <th scope="col">Module 2</th>
             <th scope="col">Module 3</th>
             <th scope="col">Module 4</th>
             <th scope="col">Module 5</th>
             <th scope="col">Module 6</th>
             <th scope="col">Module 7</th>
             <th scope="col">Module 8</th>
             <th scope="col">Module 9</th>
           </tr>
           </thead>`;

    const tableBody = createElementWithAttribute('tbody', {
      id: 'student-progress-tbody',
    });

    const errorMessage = createElementWithAttribute('p', {
      id: 'no-student-message',
      style: 'display:none',
    });
    errorMessage.innerText =
      'Currently, there is no student in this class, please add student in Manage > Profiles';

    studentTable.innerHTML = tableHeader;
    studentTable.appendChild(tableBody);
    tableScroll.appendChild(studentTable);
    tableWrapper.appendChild(tableScroll);
    tableWrapper.appendChild(errorMessage);

    this.parent.appendChild(tableWrapper);
  }

  getStudentList(classId) {
    let that = this;

    let userId = firebase.auth().currentUser.uid;
    let studentListRefProgress = database.ref(
      '/profiles/' + userId + '/classes/' + classId + '/student'
    );
    studentListRefProgress.on('value', function (snapshot) {
      let students = snapshot.val();
      if (students) {
        const studentInfoDataset = Object.entries(students);
        const studentInfo = studentInfoDataset.map((student) => {
          return {
            studentId: student[0],
            name: student[1].name,
          };
        });

        //Get student progress from firebase
        const allStudentInfo = studentInfo.map((student) => {
          return new Promise((resolve, reject) => {
            database
              .ref('/progress/' + student.studentId)
              .once('value')
              .then(function (snapshot) {
                let output = `<tr><td>${student.name}</td>`;
                const progressInfo = snapshot.val()
                  ? Object.entries(snapshot.val())
                  : undefined;

                for (let i = 0; i < Object.keys(moduleSteps).length; i++) {
                  let moduleName = `module${i + 1}`;
                  let isModuleFind = false;
                  //Check if student module world found
                  if (typeof progressInfo === 'object') {
                    progressInfo.forEach((studentModule) => {
                      if (studentModule[0] === moduleName) {
                        let currentProgress = (
                          (studentModule[1].step /
                            moduleSteps[`${moduleName}`]) *
                          100
                        ).toFixed(0);

                        if (currentProgress > 100) currentProgress = 100;

                        output += that.createProgressBar(
                          currentProgress,
                          student.studentId,
                          student.name,
                          moduleName
                        );

                        isModuleFind = true;
                      }
                    });
                  }
                  if (!isModuleFind) {
                    //No student world find related to the module, output 0%
                    output += that.createProgressBar(
                      0,
                      student.studentId,
                      student.name,
                      moduleName
                    );
                  }
                }

                output += `</tr>`;
                resolve(output);
              });
          });
        });

        Promise.all(allStudentInfo).then(function (AllStudentProgress) {
          //Append all students progress list to table
          let output = '';
          AllStudentProgress.forEach((studentItem) => {
            output += studentItem;
          });
          document.getElementById('student-progress-tbody').innerHTML = output;
          document.getElementById('table-scroll').style.display = 'block';
          document.getElementById('no-student-message').style.display = 'none';
        });
      } else {
        //Nothing find, empty student progress table
        document.getElementById('table-scroll').style.display = 'none';
        document.getElementById('no-student-message').style.display = 'block';
      }
    });
  }

  createProgressBar(progress, studentId, studentName, module) {
    const progressMap = [
      {
        condition: (currentProgress) => {
          return currentProgress >= 1 && currentProgress <= 50;
        },
        status: 'red',
      },
      {
        condition: (currentProgress) => {
          return currentProgress > 50 && currentProgress < 100;
        },
        status: 'yellow',
      },
      {
        condition: (currentProgress) => {
          return currentProgress >= 100;
        },
        status: 'green',
      },
      {
        condition: (currentProgress) => {
          return currentProgress < 1 || currentProgress === 100;
        },
        status: 'grey',
      },
    ];

    const currentProgress = progressMap.find((aProgress) => {
      return aProgress.condition(progress);
    });

    const anchor =
      progress > 0
        ? `<a class="workplace-link" target="_blank" href=${mainApp}/?origin=${classroomOrigin.origin}&class=${this.classId}&role=teacher&student=${studentId}&name=${studentName}&module=${module}>`
        : `<a class="workplace-link" href="#">`;

    return `
    <td class="progress-status ${currentProgress.status}">
    ${anchor}
          <span class="progress-bar"></span>
          <span class="progress-text">${progress}%</span>
        </a></td>
    `;
  }

  createProgressLegend() {
    const container = document.createElement('div');
    container.className = 'legend-container';
    container.innerHTML = `<span class="legend-trigger">?</span>
      <div class="progress-legend">
          <div class="progress-status green"><span class="progress-bar"></span><span class="progress-text">100%</span></div>
          <div class="progress-status yellow"><span class="progress-bar "></span><span class="progress-text">51~99%</span></div>
          <div class="progress-status red"><span class="progress-bar "></span><span class="progress-text">1~50%</span></div>
          <div class="progress-status grey"><span class="progress-bar "></span><span class="progress-text">0%</span></div>
      </div>
      `;
    this.parent.appendChild(container);
  }
}

module.exports = Progress;
