let AbstractComponent = require('../AbstractComponent.js');

class Lessons extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = document.querySelector(`.${elem.className}`);

    this.lessonDivs = [];
    this.error = false;
  }

  //Create lessons
  getLessons(lessonsArray) {
    //Get lessons based on the student id
    const studentId = getQueryVariable('student');
    const classId = getQueryVariable('class');
    const name = getQueryVariable('name');

    if (!studentId || !name) {
      let errMsg = document.createElement('h3');
      errMsg.innerHTML = `<p>Invalid id or name, unable to get lessons.</p>
      <a href="entrance.html">Back to entrance</a>
      `;
      errMsg.className = 'error-message';
      this.parent.appendChild(errMsg);
      return;
    }

    let welcomeMsg = document.createElement('h2');
    welcomeMsg.innerHTML = `Hello, ${name}. Welcome to c3d.`;
    welcomeMsg.className = 'welcome-msg';
    this.parent.appendChild(welcomeMsg);

    let lessonHeader = document.createElement('h2');
    lessonHeader.innerHTML = 'Interactive Lessons';
    lessonHeader.className = 'col-12 lesson-list-header';

    let lessonsContainer = document.createElement('div');
    lessonsContainer.className = 'container lessons-container';
    let lessonList = document.createElement('ul');
    lessonList.className = 'lesson-list row';

    //Get student module sid
    let studentModuleInfo;
    this.getStudentModuleId(studentId)
      .then((result) => {
        studentModuleInfo = result;
        console.log('Info', studentModuleInfo);
        let listItemOutput = '';

        lessonsArray.forEach((lesson, index) => {
          //Check student progress base on step/total step
          const totalStep = moduleSteps[`${lesson.module}`];

          let currentStep = 0;

          if (typeof studentModuleInfo === 'object') {
            studentModuleInfo.forEach((singleModule) => {
              if (singleModule.module === lesson.module) {
                currentStep = singleModule.step;
              }
            });
          }

          //Calculate progress based on step/Total step
          let currentProgress = ((currentStep / totalStep) * 100).toFixed(0);
          let progressDotOutput = '';
          switch (true) {
            case currentProgress >= 1 && currentProgress <= 50:
              progressDotOutput = `<span class="progress-dot red" title="Status: ${currentProgress}%">${currentProgress}%</span>`;
              break;

            case currentProgress > 50 && currentProgress < 100:
              progressDotOutput = `<span class="progress-dot yellow" title="Status: ${currentProgress}%">${currentProgress}%</span>`;
              break;

            case currentProgress >= 100:
              currentProgress = 100;
              progressDotOutput = `<span class="progress-dot green" title="Status: ${currentProgress}%">${currentProgress}%</span>`;
              break;

            default:
              progressDotOutput = `<span class="progress-dot grey" title="Status: ${currentProgress}%">${currentProgress}%</span>`;
              break;
          }

          listItemOutput += `<li class="list-item col-12 col-md-4">
          <a href="${mainApp}/?origin=${classroomOrigin.origin}&class=${classId}&role=student&student=${studentId}&name=${name}&module=${lesson.module}" class="module-link">
          <div class="item-header">
          <h3 class="lesson-title">${lesson.title}</h3>
          <div class="progress-dot-container">
          ${progressDotOutput}
          </div>
          </div>
          <div class="thumbnail-container">
          <img class="lesson-thumbnail" src="${lesson.img}" alt="lesson thumbnail" />
          </div>
          <p class="lesson-description">${lesson.description}</p>
          </a>
        
          </li>`;
        });
        lessonList.innerHTML = listItemOutput;

        lessonsContainer.appendChild(lessonHeader);
        lessonsContainer.appendChild(lessonList);
        this.parent.appendChild(lessonsContainer);
      })
      .catch((error) => {
        let errMsg = document.createElement('h3');
        errMsg.innerHTML = `<p>${error}</p>
      <a href="entrance.html">Back to entrance</a>
      `;
        errMsg.className = 'error-message';
        this.parent.appendChild(errMsg);
      });
  }

  //Get student module id from firebase
  getStudentModuleId(studentId) {
    return new Promise((resolve, reject) => {
      database
        .ref('/progress/' + studentId)
        .once('value')
        .then(function (snapshot) {
          let studentModuleInfo = snapshot.val()
            ? Object.entries(snapshot.val())
            : [];
          if (studentModuleInfo) {
            console.log(studentModuleInfo);
            studentModuleInfo = studentModuleInfo.map((info) => {
              if (info[1].step) {
                return {
                  module: info[0],
                  sid: info[1].sid,
                  step: info[1].step,
                };
              } else {
                return {
                  module: info[0],
                  sid: info[1].sid,
                  step: 0,
                };
              }
            });
          }
          resolve(studentModuleInfo);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
}

module.exports = Lessons;
