let AbstractComponent = require("../AbstractComponent.js");

class Projects extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = document.querySelector(`.${elem.className}`);

    this.error = false;
    this.init();
  }

  init() {
    let projectHeader = document.createElement("h2");
    projectHeader.innerHTML = "Projects";

    this.parent.appendChild(projectHeader);

    let parentDiv = this.parent;
    let projectsDivs = [];

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log("User:", user);
        user
          .getIdToken()
          .then(function(token) {
            // retrieve what is already there under the user profile
            return new Promise(function(resolve, reject) {
              fetch(
                "https://env3d-blockly.firebaseio.com/users/" +
                  user.uid +
                  ".json?auth=" +
                  token
              )
                .then(function(profile) {
                  return profile.json();
                })
                .then(function(json) {
                  if (!json) {
                    json = {};
                  }
                  resolve({ profile: json, token: token });
                });
            });
          })
          .then(function(u) {
            console.log("profile", u.profile);
            // create the actions -- for each world, allow clicking, renaming, and deleting
            Object.keys(u.profile).forEach((sid, index) => {
              projectsDivs[index] = document.createElement("div");
              projectsDivs[index].innerHTML =
                `<div sid=${sid}>` +
                '<a style="color: black" href="' +
                window.location.origin +
                "?id=" +
                sid +
                '">' +
                u.profile[sid] +
                "</a>" +
                "<button>rename</button><button>delete</button></div>";
              parentDiv.appendChild(projectsDivs[index]);
            });
          })
      }
    });
  }
}

module.exports = Projects;
