let AbstractComponent = require('../AbstractComponent.js');
const md5 = require('md5');


class Roles extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;

    this.error = false;

    this.init();
  }

  init() {
    firebase.auth().onAuthStateChanged(function (user) {
      // if not signin, redirect to login.html
      if (!user || !user.emailVerified) {
        window.location = `${window.location.origin}/login.html`;
      }
    });

    const student = document.getElementById('role-student');
    const teacher = document.getElementById('role-teacher');

    //Teacher click function
    teacher.addEventListener('click', (e) => {
      //Get local storage to check valid teacher
      let isValidTeacher = localStorage.getItem('isValidTeacher');
      if (isValidTeacher) {
        //Valid teacher go to dashboard
        window.location = `${window.location.origin}/index.html`;
      } else {
        //invalid teacher, require password
        this.openPwdContainer();
      }
    });

    //Student click function, open student list
    student.addEventListener('click', (e) => {
      window.location = `${window.location.origin}/student-list.html`;
    });
  }

  openPwdContainer() {
    const pwdContainer = document.querySelector('.password-container');
    pwdContainer.classList.remove('hide');
    const roleContainer = document.querySelector('.role-container');
    roleContainer.classList.add('hide');

    const errMsg = document.getElementById('error-message-pwd');

    const confirmPwdBtn = document.getElementById('confirm-pwd-btn');
    confirmPwdBtn.addEventListener('click', (e) => {
      const inputPwd = document.getElementById('second-password').value;

      let userId = firebase.auth().currentUser.uid;

      database
        .ref('/profiles/' + userId + '/info/password')
        .once('value')
        .then(function (snapshot) {
          let password = snapshot.val() ? snapshot.val() : null;
          //Have password
          if (password) {
            if (md5(inputPwd) === password) {
              errMsg.innerText = '';
              localStorage.setItem('isValidTeacher', 'true');
              //Go to teach dashboard
              window.location = `${window.location.origin}/index.html`;
            } else {
              errMsg.innerText = 'Wrong password.';
              errMsg.classList.add('show-message');
            }
          }
          //Do not have password at the first time login, use default password
          else {
            if (md5(inputPwd) === 'ce051e7919989f5e77b141f757e0aef5') {
              errMsg.innerText = '';
              localStorage.setItem('isValidTeacher', 'true');

              database
                .ref('/profiles/' + userId + '/info/password')
                .once('value')
                .then(function (snapshot) {
                  let password = snapshot.val() ? snapshot.val() : null;

                  //Init default password to firebase
                  if (password === null) {
                    database.ref('/profiles/' + userId + '/info/').set({
                      password: md5(inputPwd),
                      //Init origin
                      origin: classroomOrigin.origin,
                    });
                  }

                  //Go to teach dashboard
                  window.location = `${window.location.origin}/index.html`;
                });
            } else {
              errMsg.innerText = 'Wrong password.';
              errMsg.classList.add('show-message');
            }
          }
        });
    });

    const goBackBtn = document.getElementById('go-back-btn');
    goBackBtn.addEventListener('click', () => {
      this.closePwdContainer();
    });
  }

  closePwdContainer() {
    const pwdContainer = document.querySelector('.password-container');
    pwdContainer.classList.add('hide');
    const roleContainer = document.querySelector('.role-container');
    roleContainer.classList.remove('hide');
  }
}

module.exports = Roles;
