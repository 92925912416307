let AbstractComponent = require('../AbstractComponent.js');

class AddClass extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;

    this.menus = [];
    this.error = false;
    this.init();
  }

  init() {
    this.createAddClassForm();
  }

  //Init add class form
  createAddClassForm() {
    let formContent = `<div class="form-group">
            <label class="manage-input-title" for="class-name">Class Name</label>
            <input placeholder="Enter class name" class="form-control" type="text" name="class-name" id="form-input-class-name">
        </div>
        <p class="text-red" id="add-classes-message"></p>
        <button class="form-submit-btn" type="submit">Create</button>
        `;

    let addClassForm = document.createElement('form');
    addClassForm.innerHTML = formContent;
    addClassForm.action = '#';
    addClassForm.method = 'POST';
    addClassForm.addEventListener('submit', this.addNewClasses);
    this.parent.appendChild(addClassForm);
  }

  //Add new classes into firebase
  addNewClasses(e) {
    e.preventDefault();

    let userId = firebase.auth().currentUser.uid;

    //Valid user, create new class
    if (userId) {
      let msg = document.getElementById('add-classes-message');
      msg.classList.remove('text-green');
      let inputClassName = document
        .getElementById('form-input-class-name')
        .value.trim();
      if (inputClassName && inputClassName.length > 1) {
        let newClasses = database
          .ref('/profiles/' + userId + '/classes')
          .push();
        newClasses.set(
          {
            name: inputClassName,
          },
          (error) => {
            if (error) {
              msg.innerText = error;
            } else {
              msg.innerText =
                'Successfully created new class ' + inputClassName;
              msg.classList.add('text-green');
              document.getElementById('form-input-class-name').value = '';
              //Update class list in Add Student & Profiles submenu & Progress view

              const selectClassContainerProfile = document.getElementById(
                'form-select-class-name-profile'
              );
              const selectClassContainerProgress = document.getElementById(
                'progress-select-class-name'
              );

              database
                .ref('/profiles/' + userId + '/classes')
                .once('value')
                .then((snapshot) => {
                  let classList = snapshot.val();
                  const classInfo = Object.entries(classList);

                  //Output HTML element for select list
                  let selectOptions = '';

                  classInfo.forEach((singleClass) => {
                    let classId = singleClass[0];
                    let className = singleClass[1].name;
                    selectOptions += `<option value=${classId} >${className}</option>`;
                  });

                  //Insert all the class options in different view selector
                  selectClassContainerProfile.innerHTML = selectOptions;
                  selectClassContainerProgress.innerHTML = selectOptions;
                  document.getElementById(
                    'assignment-select-class-name'
                  ).innerHTML = selectOptions;
                  document.getElementById(
                    'assignment-progress-select-class-name'
                  ).innerHTML = selectOptions;
                });
            }
          }
        );
      } else {
        msg.innerText = 'Please input valid class name.';
      }
    } else {
      alert('Invalid User, please login.');
    }
  }
}

module.exports = AddClass;
