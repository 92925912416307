let AbstractComponent = require("../AbstractComponent.js");


class Drawer extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;

    this.menus = [];
    this.error = false;
    this.init();
  }

  init() {
    let drawerLogo = document.createElement("img");
    drawerLogo.src="./media/c3d_logo_name.png";
    drawerLogo.alt="c3d logo";
    drawerLogo.className = "logo";

    let drawerLogoSmall = document.createElement("img");
    drawerLogoSmall.src="./media/c3dlogo_small.png";
    drawerLogoSmall.alt="c3d logo";
    drawerLogoSmall.className = "logo logo-small";
    
    let drawerLogoContainer = document.createElement("div");
     drawerLogoContainer.className = "logo-container";

    let logoLink = document.createElement("a");
    logoLink.setAttribute('href','http://canada.c3d.io/');
    logoLink.setAttribute('target','_blank');

     logoLink.appendChild(drawerLogo);
     logoLink.appendChild(drawerLogoSmall);
     drawerLogoContainer.appendChild(logoLink);
    

    this.parent.appendChild(drawerLogoContainer);


    let parentDiv = this.parent;
    let projectsDivs = [];

  }

  //Create Dashboard Menu
  createMenu(menuItemArray)
  { 
    let menuContainer = document.createElement("ul");
    menuContainer.className = "dashboard-menu";
    
    menuItemArray.map((menuItem,index)=>{
      this.menus[index] = document.createElement('li');
      if(index === 0)
      {
        this.menus[index].className = 'menu-item active';
      }
      else{
        this.menus[index].className = 'menu-item';
      }
    
      this.menus[index].setAttribute("view-id",menuItem.viewId);
      this.menus[index].id = `menu-item-${menuItem.viewId}`;

      this.menus[index].innerHTML = `<a href="#${menuItem.viewId}"><div class="link-content"><img class="menu-icon" src="${menuItem.icon}" alt="icon-${menuItem.name}" /><img class="menu-icon-selected" src="${menuItem.iconSelected}" alt="icon-${menuItem.name}-selected" /><span>${menuItem.name}</span></div></a>`;
      this.menus[index].addEventListener('click',()=>{
        //Active Menu item
        let allMenuItem = document.querySelectorAll('.menu-item');
        for(let i = 0;i<allMenuItem.length;i++)
        {
          allMenuItem[i].className="menu-item";
        }
        this.menus[index].className = 'menu-item active';

        //Active Main screen view
        let allScreenView = document.querySelectorAll('.screen-view');
        for(let i=0;i<allScreenView.length;i++)
        {
          allScreenView[i].className = "screen-view";
        }

        let targetViewId = this.menus[index].getAttribute('view-id');

        let activeScreenView = document.querySelector(`#${targetViewId}`);
        activeScreenView.className = "screen-view active-view";

      })
      menuContainer.appendChild(this.menus[index])

    });
    
    let navContainer = document.createElement('nav');
    navContainer.className = "navigation";
    navContainer.appendChild(menuContainer);

    this.parent.appendChild(navContainer);

  }

    //Create Valid User status section
    //user: the object from firebase
    createUserStatus(user){

      let userStatus = document.createElement('div');
      userStatus.className = "user-status";
      
      let statusContainer = document.createElement('div');

      let userName = user.displayName ? user.displayName.split(" ") : user.email;
      let userPhoto = user.photoURL;

      let statusInfo = document.createElement('div');
      statusInfo.className = "user-status-info";
      statusInfo.innerHTML = `<p class="user-status-name">${userName}</p>
      <p class="status-sign"> <span id="signOut">Sign out</span></p>`;
      // statusInfo.innerHTML = `<p class="user-status-name">${userName[0]}</p>
      // <p class="status-sign"><span class="online"></span>online</p>`;

      statusContainer.className = "user-status-container";
      if(userPhoto)
      {
        statusContainer.innerHTML = `<img class="user-photo" src="${userPhoto}" alt="${userName[0]}-photo" />`;
      }
      statusContainer.appendChild(statusInfo);

   

      userStatus.appendChild(statusContainer);

      this.parent.appendChild(userStatus);

      document
      .querySelector("#signOut")
      .addEventListener("click", function () {
          localStorage.removeItem("storageId");
          localStorage.removeItem('isValidTeacher');
          localStorage.removeItem('emailVerified');


          // session signout

      const csrfToken = getCookie('csrfToken')

      const requestBody = JSON.stringify({
        csrfToken: csrfToken,
      })
      
          fetch(`${cloudURL}/sessionLogout`, {
            method: 'POST',
            credentials: "include",
            headers: {
              'Access-Control-Allow-Origin': 'api.myc3d.test',
              "Content-Type": "application/json",
            },
            body: requestBody
          })
            .then(result => {
              firebase
              .auth()
              .signOut();
              window.location.href = window.location.origin + "/login.html";
              return 
            })
      });
  
    }
}

module.exports = Drawer;
