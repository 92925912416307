let AbstractComponent = require("../AbstractComponent.js");
let signup = require("./signup.js");
let emailVerification = signup.sendVerification;

class Verification extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.button = [];
    this.container = document.createElement("div");

    ["Resend email", "Back to homepage", "Log out"].forEach((label, index) => {
      this.button[index] = document.createElement("button");
      this.button[index].innerHTML = label;

      this.button[index].addEventListener("click", evt => {
        this.handleButton(index, evt);
      });

      this.container.appendChild(this.button[index]);
    });

    this.elem.appendChild(this.container);
    this.init();
  }

  init() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (user.emailVerified) {
          window.location.href = window.location.origin;
        } else {
          emailVerification();
        }
      }
    });
  }

  handleButton(index, e) {
    e.preventDefault();

    switch (index) {
      case (index = 0):
        emailVerification();
        break;
      case (index = 1):
        window.location.href = "https://www.c3d.io/";
        break;
      case (index = 2): 
        firebase.auth().signOut();
        window.location.href = `${window.location.origin}/login.html`;
        console.log("log out")
        break;
      default:
        break;
    }
  }
}

module.exports = Verification;
