function signUpWithMail(email, password) {
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // ...
      if (errorCode == "auth/weak-password") {
        alert("The password is too weak.");
      } else {
        alert(errorMessage);
      }
      console.log(error);
    });
}

function sendVerification() {
  var user = firebase.auth().currentUser;

  var actionCodeSettings = {
    url: window.location.origin
  };

  user
    .sendEmailVerification(actionCodeSettings)
    .then(function() {
      // Email sent.
      console.log("email sent");
    })
    .catch(function(error) {
      // An error happened.
      console.log(error)
    });
}

module.exports = { signUpWithMail, sendVerification };
