let AbstractComponent = require("../AbstractComponent.js");
const md5 = require("md5");

class Setting extends AbstractComponent {
    constructor(elem) {
      super(elem);
  
      this.parent = elem;
  
      this.menus = [];
      this.error = false;
      this.init();
    }
  
    init() {
      this.createChangePwdForm();
       

    }


    //Init change password form
    createChangePwdForm(){

      let formContent = `
            <div class="form-group">
            <h2 class="manage-input-title" for="password">Change Teacher Dashboard Password<br/> (A Password used to open Teacher Dashboard)</h2>
            <input placeholder="Enter old password" class="form-control pwd-input" type="password" name="old-password" id="form-input-old-password">
            
            <input placeholder="Enter new password" class="form-control pwd-input" type="password" name="new-password" id="form-input-new-password">
            <input placeholder="Confirm new password" class="form-control pwd-input" type="password" name="confirm-new-password" id="form-input-confirm-new-password">
            
            </div>
        <p class="text-red" id="change-password-message"></p>
        <button class="form-submit-btn" type="submit">Save</button>
        `;

        let changePwdForm = document.createElement('form');
        changePwdForm.innerHTML = formContent;
        changePwdForm.className = "change-password-form";
        changePwdForm.action="#";
        changePwdForm.method="POST";
        changePwdForm.addEventListener('submit',this.changePwd);
        this.parent.appendChild(changePwdForm);
    }


    //Update new password to firebase
    changePwd(e){
        e.preventDefault();
        
        let userId = firebase.auth().currentUser.uid;
 
        //Valid user, check input password
        if(userId){
          let msg = document.getElementById('change-password-message');
          msg.classList.remove("text-green");
          const oldPwd = document.getElementById('form-input-old-password');
          const newPwd = document.getElementById('form-input-new-password');
          const confirmNewPwd = document.getElementById('form-input-confirm-new-password');
          
          //Valid user input password
          if(newPwd.value.length<=6)
          {
            msg.innerText = "Password must be at least 6 characters.";

            return
          }
          else if(newPwd.value !== confirmNewPwd.value)
          {
            msg.innerText = "The passwords you entered do not match each other.";

            return
          }
          
          //Valid old password based on firebase data
          database.ref('/profiles/' + userId + '/info/password').once('value').then(function(snapshot) {
            let oldPassword = snapshot.val()? snapshot.val() : null;
            if(md5(oldPwd.value)!==oldPassword)
            {
              msg.innerText = "The old password was wrong.";
            }
            else{
              //Update new password
              database.ref('/profiles/' + userId + '/info/').set({
                password: md5(confirmNewPwd.value)
              },()=>{
                msg.innerText = "Successfully update password.";
                msg.classList.add("text-green");
                oldPwd.value = "";
                newPwd.value = "";
                confirmNewPwd.value = "";
              });
            }
          
          })
       
        }
        else{
          alert('Invalid User, please login.');
        }
      
    }


    
  
  
  }
  
  module.exports = Setting;
  