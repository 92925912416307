require("./components/config.js");

window["Component"] = {
  Components: {},
  Auth: require("./components/Auth/index.js"),
  Portal: require("./components/Portal/index.js"),
  Profile: require("./components/Auth/Profile.js"),
  DashboardMenu: require("./components/Menu/index.js"),
  Classes: require('./components/Classes/index.js'),
  Resources: require("./components/Resources/Resources.js"),
  Videos:require("./components/Videos/Videos.js"),
  Support: require("./components/Support/Support.js"),
  Manage:require("./components/Manage/index.js"),

  //Entrance page
  Roles: require("./components/Entrance/Roles.js"),
  //Student list page
  List: require("./components/StudentList/List.js"),

  BrowserDetect: require("./components/BrowserDetect")
};
