let AbstractComponent = require("../AbstractComponent.js");

class Videos extends AbstractComponent {
    constructor(elem) {
      super(elem);
  
      this.parent = elem;
  
      this.menus = [];
      this.error = false;
      this.init();
    }
  
    init() {
       
      
      let videoListContainer = document.createElement("div");
      videoListContainer.className = "video-list-container container";
      videoListContainer.id = "video-list-container";


      this.parent.appendChild(videoListContainer);

      let introVideo = [{videoTitle:``,videoCode:`TioD-yBDEsg`,videoLength:''}]
      
      this.createVideoList("Welcome To C3D", introVideo);

      let videoList = [{videoTitle:`VARIABLES: How to set a variable in C3D.io to animate a 3D object`,videoCode:`dejkw2aHDOI`,videoLength:'1:10'},
      {videoTitle:`COORDINATES: How to define the location of a 3D object`,videoCode:`R2UOClToh3Q`,videoLength:'1:12'},
      {videoTitle:`Lesson 2-1 Move Object`,videoCode:`BMKdAoSnKKQ`,videoLength:'0:46'},
      {videoTitle:`Lesson 2-2 Turn Objects`,videoCode:`8juGkAHsQWo`,videoLength:'0:19'},
      {videoTitle:`Do in Sequence: Making sequential animation in a 3D world`,videoCode:`Vr1sVvlua1Q`,videoLength:'0:34'},
      {videoTitle:`EVENTS: How to start an animation when a user looks at a 3D object`,videoCode:`ctRUmHQqB0I`,videoLength:'0:36'},
      {videoTitle:`Lesson 3-2 How to see your VR world with VR headset`,videoCode:`EnTllUEWkj0`,videoLength:'1:30'},
      {videoTitle:`Lesson 4 If Statement: How to make something happen when a condition is met`,videoCode:`5Bx_FpG2-ng`,videoLength:'0:28'},  
      {videoTitle:`Lesson 5 Loop`,videoCode:`5N0aMDa6pck`,videoLength:'0:13'},  
      {videoTitle:`Camera Position`,videoCode:`-2S_Ec9alMo`,videoLength:'0:18'}, 
    ]

      this.createVideoList("Lesson Videos", videoList);
  
    }

    //Create Video List
    createVideoList(title,videoListArray){
        
        let listTitle = document.createElement("h3");
        listTitle.className = "video-list-title";
        listTitle.innerText = title;

        let videoList = document.createElement("ul");
        videoList.className = "video-list row";



        document.getElementById('video-list-container').appendChild(listTitle);
        document.getElementById('video-list-container').appendChild(videoList);

        let outputItems = ``;
        //Output video list item
        videoListArray.map(videoItem =>{
           let output = `
            <li class="video-item col-6 col-md-4">
                <div class="video-container">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/${videoItem.videoCode}?rel=0" frameborder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="video-info-container">
                    <span class="video-title">${videoItem.videoTitle}</span>
                    <span class="video-length">${videoItem.videoLength}</span>
                </div>
            </li>`;

            outputItems += output;

        });

        videoList.innerHTML = outputItems;

    }
  
  }
  
  module.exports = Videos;
  