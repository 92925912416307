let AbstractComponent = require("../AbstractComponent.js");


class List extends AbstractComponent {
    constructor(elem) {
      super(elem);
  
      this.parent = elem;
  
      this.error = false;
        
      this.init();
 
    }
  
    init() {
      this.createClassList();
      this.getClassesList();
      this.createStudentListContainer();

    }
    createClassList(){

      let formContent = `
        <h3 class="sub-title">Class</h3>
        <div class="form-group">
            <select name="select-class-name" id="form-select-class-name-profile" class="form-control">
            <option>No class found</option>
            </select>
        </div>
        `;

        let addClassForm = document.createElement('form');
        addClassForm.innerHTML = formContent;
        addClassForm.action="#";
        addClassForm.method="POST";

      this.parent.appendChild(addClassForm);

      //OnChange event for selector class
      const selectClassContainer = document.getElementById('form-select-class-name-profile');
      selectClassContainer.addEventListener('change',(e)=>{
          const classId = selectClassContainer.value;

          //Update student list
          this.updateStudentList(classId);

      });

    }

    createStudentListContainer(){
      let studentListTitle = document.createElement("h3");
      studentListTitle.className = "sub-title";
      studentListTitle.innerText = "Students";

      this.parent.appendChild(studentListTitle);

      let studentProfileListContainer = document.createElement("div");
      studentProfileListContainer.id = "student-list-container";

      let studentProfileList = document.createElement("ul");
      studentProfileList.id = "student-profile-list";
      studentProfileList.className = "list-group";

      studentProfileListContainer.appendChild(studentProfileList);

      this.parent.appendChild(studentProfileListContainer);


  }

     //Get Classes list
     getClassesList(){
      
      let userId = firebase.auth().currentUser.uid;
      let that = this;

      const selectClassContainer = document.getElementById('form-select-class-name-profile');

      //Fetch Class list from firebase
      database.ref('/profiles/' + userId + '/classes').once('value').then(function(snapshot) {

        let classList = snapshot.val();
        if(classList)
        {
          const classInfo = Object.entries(classList);

          //Output HTML element for select list
          let selectOptions = '';
  
          classInfo.forEach(singleClass => {
            let classId = singleClass[0];
            let className = singleClass[1].name;
  
            selectOptions+= className ? `<option value=${classId} >${className}</option>` : ``;
          });
  
          selectClassContainer.innerHTML = selectOptions;
          
        
          //Update student list
          that.updateStudentList(selectClassContainer.value);
        }

      });
    }

    updateStudentList(classId){
      //Get student list base on class id
      
      let userId = firebase.auth().currentUser.uid;
      let that = this;

       //Fetch Class list from firebase
       database.ref('/profiles/' + userId + '/classes/' + classId + '/student').once('value')
       .then(function(snapshot) {

         const studentList = document.getElementById('student-profile-list');

         studentList.innerHTML = "";

         let students = snapshot.val();
         if(students)
         {
           const studentInfoDataset = Object.entries(students);
 
           studentInfoDataset.forEach( student => {
           const p = document.createElement('li');
            p.className = "list-group-item single-student";
            p.setAttribute('student-id',student[0]);

            const studentInfo = document.createElement('div');
            studentInfo.className = "student-info";
            studentInfo.innerHTML = `<span class="student-name">${student[1].name}</span>`;


            const goBtn = document.createElement('button');
            goBtn.className = "go-portal-btn";
            goBtn.innerText = "Go";
            goBtn.setAttribute('student-id',student[0]);
            goBtn.setAttribute('student-name',student[1].name);
            goBtn.addEventListener('click',(e)=>{
              let studentId = e.target.getAttribute('student-id');
              let studentName = e.target.getAttribute('student-name');
              // let confirming = prompt("Please enter your name to confirm: ");

              // if(confirming === studentName) {
              //   window.location = `${window.location.origin}/portal.html?class=${classId}&student=${studentId}&name=${studentName}`;
              // } else {
              //   alert("Sorry your confirmation is not correct")
              // }

              let confirming;

              do {
                confirming = prompt('Enter your profile name (e.g. If your profile name is Becky, enter "Becky")');
                if (!confirming) {
                  break;
                }
                console.log(studentName.toLowerCase(), confirming.toLowerCase(), confirming.toLowerCase() === studentName.toLowerCase())
                if(confirming.toLowerCase() === studentName.toLowerCase()) {
                  window.location = `${window.location.origin}/portal.html?class=${classId}&student=${studentId}&name=${studentName}`;
                  break;
                }
              } while (confirming !== studentName);
            })
    
            const btnContainer = document.createElement("span");
            btnContainer.className = "btn-container";

            btnContainer.appendChild(goBtn);

            studentInfo.appendChild(btnContainer);
           
            p.appendChild(studentInfo);

            studentList.appendChild(p);



         });
         }
         
 
         
       
       });
   }
  
  }
  
  module.exports = List;
  