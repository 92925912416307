let AbstractComponent = require('../AbstractComponent.js');
let signIn = require('./login.js');
let signInWithMail = signIn.signInWithMail;

const {
  createElementWithAttribute,
} = require('../../utils/functions');

class Auth extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.isLogin = elem.id === 'login' ? true : false;
    this.form = document.createElement('form');
    this.label = [];
    this.input = [];
    this.button = [];

    this.header = document.createElement('h2');
    this.header.innerHTML = this.isLogin ? 'Login' : 'Sign up';
    this.form.appendChild(this.header);

    // Can add more fields for signup if needed
    let form = this.isLogin
      ? ['Password']
      : ['Email', 'Password', 'Repeat Password'];
    form.forEach((label, index) => {
      this.label[index] = document.createElement('label');
      this.label[index].innerHTML = label;

      this.input[index] = document.createElement('input');
      this.input[index].type = label.includes('Password') ? 'password' : 'text';

      this.form.appendChild(this.label[index]);
      this.form.appendChild(this.input[index]);
      this.form.appendChild(document.createElement('br'));
    });

    if (!this.isLogin) {
      // Check box for term of service and privacy policy
      let checkboxDiv = document.createElement('div');
      checkboxDiv.className = 'checkbox';

      this.checkbox = document.createElement('input');

      let checkboxLabel = document.createElement('label');
      this.checkbox.setAttribute('type', 'checkbox');
      this.checkbox.setAttribute('name', 'checkbox');

      checkboxLabel.innerHTML = `By clicking the button below, you agree to 
        <a href="https://canada.c3d.io/terms-of-service.html" target="_blank">Terms of Service</a> and 
        <a href="https://www.c3d.io/codeca-privacy-policy" target="_blank">Privacy Policy</a>`;
      checkboxLabel.setAttribute('for', 'checkbox');

      checkboxDiv.appendChild(this.checkbox);
      checkboxDiv.appendChild(checkboxLabel);
      this.form.appendChild(checkboxDiv);
    }

    // use loop if there is more login methods in the future
    ['email'].forEach((el, index) => {
      this.button[index] = document.createElement('button');
      switch (index) {
        case (index = 0):
          if (this.isLogin === true) {
            this.button[index].innerHTML = 'Login';
          } else {
            this.button[index].innerHTML = 'Sign up';
          }
          break;
        case (index = 1):
          this.button[index].innerHTML = `Login`;
          break;
        default:
          break;
      }
      this.button[index].addEventListener('click', (evt) => {
        this.handleButton(index, evt);
      });
      this.form.appendChild(this.button[index]);
    });
    this.form.appendChild(document.createElement('br'));

    this.elem.appendChild(this.form);

    this.init();
    // End of contructor
  }

  init() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //login in backend-session, then a session cookie will be sent back
        this.sessionLogin(user).then((status) => {
          if (status === 200) {
            if (user.emailVerified) {
              localStorage.setItem('uid', user.uid);
              //Verified User, go to entrance.html to select role
              window.location.href = `${window.location.origin}/entrance.html`;
            } else {
              window.location.href = `${window.location.origin}/verification.html`;
            }
          }
        });
      }
    });
  }

  handleButton(index, e) {
    e.preventDefault();
    signInWithMail("info@c3d.io", this.input[0].value);
  }

  autoSignin() {
    let searchParams = new URLSearchParams(window.location.search);

    const pwd = searchParams.get("pwd");

    // const key = process.env.PWD_EN_KEY;
    const key = "motcaikeyratladaivaxamxicandenhonmuoisaukitu";
 
    if(pwd) {
      // Create an encryptor:
      const encryptor = require('simple-encryptor')(key);
      
      const decrypted = encryptor.decrypt(pwd);

      signInWithMail("info@c3d.io", decrypted);
    }

  }

  sessionLogin(user) {
    return user.getIdToken().then(function (idToken) {
      document.body.classList.add("loading");

      let rightDiv = document.querySelector(".right");

      let loading = createElementWithAttribute("div", {
        class: "spinner-border text-dark",
        role: "status"
      });

      rightDiv.appendChild(loading);

      let span = createElementWithAttribute("span", {

      });

      span.innerHTML = "Signing in. Please wait a moment...";

      rightDiv.appendChild(span);

      return fetch(`${cloudURL}/sessionLogin`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Access-Control-Allow-Origin': cloudURL,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }).then((result) => {
        console.log('here');
        return result.status;
      });
    });
  }
}
module.exports = Auth;
