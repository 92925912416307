let AbstractComponent = require('../AbstractComponent.js');

class ClassesMenu extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;

    this.menus = [];
    this.error = false;
    this.init();
  }

  init() {
    this.createSubmenu();

    this.insertMenuItem('Modules', 'classes-progress', true);
    this.insertMenuItem('Assignments', 'classes-assignment', false);
    this.insertMenuItem('Reports', 'classes-reports', false);
    this.insertMenuItem('Forums', 'classes-forums', false);
  }

  //Create Submenu
  createSubmenu() {
    let submenuContainer = document.createElement('nav');
    submenuContainer.className = 'navigation-submenu active-submenu';
    // submenuContainer.className = "navigation-submenu";
    submenuContainer.id = 'classes-submenu';

    let submenuList = document.createElement('ul');
    submenuList.className = 'submenu-list';
    submenuList.id = 'classes-submenu-list';
    let drawerLogo = document.createElement('img');
    drawerLogo.src = './media/c3d_logo_name.png';
    drawerLogo.alt = 'c3d logo';
    drawerLogo.className = 'logo opacity';

    let drawerLogoSmall = document.createElement('img');
    drawerLogoSmall.src = './media/c3dlogo_small.png';
    drawerLogoSmall.alt = 'c3d logo';
    drawerLogoSmall.className = 'logo logo-small opacity';

    let drawerLogoContainer = document.createElement('div');
    drawerLogoContainer.className = 'logo-container';
    drawerLogoContainer.appendChild(drawerLogo);
    drawerLogoContainer.appendChild(drawerLogoSmall);

    submenuContainer.appendChild(drawerLogoContainer);
    submenuContainer.appendChild(submenuList);

    let hideSubmenuBtn = document.createElement('span');
    hideSubmenuBtn.className = 'submenu-hide-btn';
    hideSubmenuBtn.innerHTML = `<img src="./media/arrow-right.svg" alt="arrow-right">`;
    hideSubmenuBtn.addEventListener('click', () => {
      let submenu = document.getElementById('classes-submenu');
      submenu.className = 'navigation-submenu';
      let showSubmenuBtn = document.querySelector('#classes-submenu-show-btn');
      showSubmenuBtn.className = 'submenu-show-btn';

      let allClassesSection = document.querySelectorAll('.classes-section');
      //Change section grid column
      for (let i = 0; i < allClassesSection.length; i++) {
        allClassesSection[i].classList.add('without-submenu');
      }
    });

    submenuContainer.appendChild(hideSubmenuBtn);

    let showSubmenuBtn = document.createElement('span');
    showSubmenuBtn.className = 'submenu-show-btn hide';
    showSubmenuBtn.innerHTML = `<img src="./media/arrow-right.svg" alt="arrow-right">`;
    showSubmenuBtn.id = 'classes-submenu-show-btn';

    showSubmenuBtn.addEventListener('click', () => {
      let submenu = document.getElementById('classes-submenu');
      submenu.className = 'navigation-submenu active-submenu';
      showSubmenuBtn.className = 'submenu-show-btn hide';

      let allClassesSection = document.querySelectorAll('.classes-section');
      //Change section grid column
      for (let i = 0; i < allClassesSection.length; i++) {
        allClassesSection[i].classList.remove('without-submenu');
      }
    });

    this.parent.appendChild(showSubmenuBtn);

    this.parent.appendChild(submenuContainer);
  }

  //Insert menu item into submenu
  insertMenuItem(title, sectionId, isDefaultSection) {
    let classesSubmenuList = document.getElementById('classes-submenu-list');
    let outputListItem = document.createElement('li');
    outputListItem.setAttribute('section-id', sectionId);

    outputListItem.addEventListener('click', (e) => {
      let allClassesSubmenu = document.querySelectorAll(
        '#classes-submenu-list .list-item'
      );
      for (let i = 0; i < allClassesSubmenu.length; i++) {
        allClassesSubmenu[i].className = 'list-item';
        //Temporary hide the report menu -- Delete later
        if (i == 2) {
          allClassesSubmenu[i].className = 'list-item unclickable';
        }
      }
      outputListItem.className = 'list-item active';

      let targetSectionId = outputListItem.getAttribute('section-id');
      let activeSection = document.getElementById(targetSectionId);
      let allResourceSection = document.querySelectorAll('.classes-section');
      //Clear all active section
      for (let i = 0; i < allResourceSection.length; i++) {
        allResourceSection[i].className = 'classes-section container';
      }

      activeSection.className = 'classes-section active-section container';
    });
    if (isDefaultSection) {
      outputListItem.className = 'list-item active';
    } else {
      outputListItem.className = 'list-item';
    }

    //Temporary hide the report menu -- Delete later
    if (sectionId == 'classes-reports') {
      outputListItem.className = 'list-item unclickable';
    }

    let listItemText = document.createElement('span');
    listItemText.setAttribute('section-id', sectionId);
    listItemText.innerText = title;

    outputListItem.appendChild(listItemText);

    classesSubmenuList.appendChild(outputListItem);
  }
}

module.exports = ClassesMenu;
