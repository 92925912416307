function signInWithGoogle() {
  var provider = new firebase.auth.GoogleAuthProvider();

  // firebase.auth().signInWithRedirect(provider);
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function(result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      // ...
    })
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...

      console.log(error)
    });
}

function signInWithMail(email, password) {
  // console.log(this.input[0].value + " " + this.input[1].value);
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode === "auth/wrong-password") {
        alert("Incorrect credential. Email or password is incorrect.");
      } else {
        alert(errorMessage);
      }
      console.log(errorCode + ": " + errorMessage);
      // ...
    });
}

module.exports = { signInWithGoogle, signInWithMail };
