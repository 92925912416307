let AbstractComponent = require('../AbstractComponent.js');

class Resources extends AbstractComponent {
  constructor(elem) {
    super(elem);

    this.parent = elem;

    this.menus = [];
    this.error = false;
    this.init();
  }

  init() {
    this.createSubmenu();

    let lessonPlans = [
      {
        title: 'Set up C3D.io Classroom',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=1sRo0oeYSeWs0Hun-26v-ycY5NUHLiMyJ',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 1 - Spaceship',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=1QdJqeiIMQIO6NRDHdQlLKqQIeTtXsu1u',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 2 - Traffic System',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=1WVRRjOxDJmED4lP5h-5ML8EyIbsaNKW1',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 3 - Waterway',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=1pSht0ylj4e13QX9WyiosHIyeM122gbsx',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 4 - IF Statements',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=1pu_pX9hg7RgYSe9FsBsLuCQip4jKWBj5',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 5 - Loop',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=1w0i9ze9kMiUA7wAXfArcbEXazbkzv1KW',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 6 - Nested Loop',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=14xQ1PFspUSrT4yT4w06LwZI0ECNAJkMj',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 7 - Functions',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=1xwZ8WdvQFAusdQe3mpqYHHUhWi6T4MPZ',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 8 - Camera Position',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=1doCNTgqjjPthv-uYwnztSpiNXu9UPGnx',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
      {
        title: 'Module 9 - Coding Challenge',
        description: '',
        resourceURL:
          'https://drive.google.com/open?id=19U_Z9hzKDZfsHbw9t9jkcmyK0F7RSh2I',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
    ];

    this.createResourceSection(
      'Lesson Plans',
      'resource-lesson-plan',
      lessonPlans,
      true
    );

    let guides = [
      {
        title: 'Facilitators Guide',
        description:
          'A complete guide and resource for usingC3D.io in the classroom',
        resourceURL:
          'https://drive.google.com/file/d/1bmTUycBZmVuEe73waruyc7-dqmFNTNd8/view',
        thumbnailURL: './assets/lesson-plan/screenshot-facilitator-guide.png',
      },
      {
        title: '4 Lesson Handout',
        description: 'Learn to code virtual reality',
        resourceURL:
          'https://drive.google.com/file/d/1vtOeGpWY5NaqOYpwpoRKavy2Svhldao-/view',
        thumbnailURL: './assets/lesson-plan/screenshot-4lesson-handout.png',
      },
      {
        title: '4 Session Lesson Plan',
        description: 'Introduction to coding with C3D',
        resourceURL:
          'https://drive.google.com/file/d/1jI0mLMo1yTgl36KRXrqv0y-iCqxEDH9j/view',
        thumbnailURL: './assets/lesson-plan/screenshot-4session-plan.png',
      },
    ];

    this.createResourceSection('Guides', 'resource-guides', guides, false);

    let worksheet = [
      {
        title: 'Forecasting Student Worksheet',
        description: '',
        resourceURL: './assets/worksheet/student worksheet.pdf',
        thumbnailURL: './assets/worksheet/screenshot-forecasting-worksheet.png',
      },
    ];

    this.createResourceSection(
      'Worksheet',
      'resource-worksheet',
      worksheet,
      false
    );

    this.createResourceSection('Examples', 'resource-example', [], false);

    //TODO --delete later Coming soon text
    const comingText = document.createElement('h3');
    comingText.innerText = 'Coming Soon...';
    document.getElementById('resource-example').appendChild(comingText);
  }

  //Create Submenu
  createSubmenu() {
    let submenuContainer = document.createElement('nav');
    submenuContainer.className = 'navigation-submenu active-submenu';
    submenuContainer.id = 'resource-submenu';

    let submenuList = document.createElement('ul');
    submenuList.className = 'submenu-list';
    submenuList.id = 'resource-submenu-list';
    let drawerLogo = document.createElement('img');
    drawerLogo.src = './media/c3d_logo_name.png';
    drawerLogo.alt = 'c3d logo';
    drawerLogo.className = 'logo opacity';

    let drawerLogoSmall = document.createElement('img');
    drawerLogoSmall.src = './media/c3dlogo_small.png';
    drawerLogoSmall.alt = 'c3d logo';
    drawerLogoSmall.className = 'logo logo-small opacity';

    let drawerLogoContainer = document.createElement('div');
    drawerLogoContainer.className = 'logo-container';
    drawerLogoContainer.appendChild(drawerLogo);
    drawerLogoContainer.appendChild(drawerLogoSmall);

    submenuContainer.appendChild(drawerLogoContainer);
    submenuContainer.appendChild(submenuList);

    let hideSubmenuBtn = document.createElement('span');
    hideSubmenuBtn.className = 'submenu-hide-btn';
    hideSubmenuBtn.innerHTML = `<img src="./media/arrow-right.svg" alt="arrow-right">`;
    hideSubmenuBtn.addEventListener('click', () => {
      let submenu = document.getElementById('resource-submenu');
      submenu.className = 'navigation-submenu';
      let showSubmenuBtn = document.querySelector(
        '#resources-submenu-show-btn'
      );
      showSubmenuBtn.className = 'submenu-show-btn';

      let allResourceSection = document.querySelectorAll('.resource-section');
      //Change section grid column
      for (let i = 0; i < allResourceSection.length; i++) {
        allResourceSection[i].classList.add('without-submenu');
      }
    });

    submenuContainer.appendChild(hideSubmenuBtn);

    let showSubmenuBtn = document.createElement('span');
    showSubmenuBtn.className = 'submenu-show-btn hide';
    showSubmenuBtn.innerHTML = `<img src="./media/arrow-right.svg" alt="arrow-right">`;
    showSubmenuBtn.id = 'resources-submenu-show-btn';

    showSubmenuBtn.addEventListener('click', () => {
      let submenu = document.getElementById('resource-submenu');
      submenu.className = 'navigation-submenu active-submenu';
      showSubmenuBtn.className = 'submenu-show-btn hide';

      let allResourceSection = document.querySelectorAll('.resource-section');
      //Change section grid column
      for (let i = 0; i < allResourceSection.length; i++) {
        allResourceSection[i].classList.remove('without-submenu');
      }
    });

    this.parent.appendChild(showSubmenuBtn);

    this.parent.appendChild(submenuContainer);
  }

  //Create Resources List
  createResourceSection(title, sectionId, resourcesArray, isDefaultSection) {
    let resourceSection = document.createElement('section');
    resourceSection.id = sectionId;
    if (isDefaultSection) {
      resourceSection.className = 'resource-section active-section container';
    } else {
      resourceSection.className = 'resource-section container';
    }

    let sectionTitle = document.createElement('h3');
    sectionTitle.className = 'resource-section-title';
    sectionTitle.innerText = title;

    resourceSection.appendChild(sectionTitle);

    let resourceList = document.createElement('ul');
    resourceList.className = 'resource-list row';

    let outputItems = ``;
    //Output video list item
    resourcesArray.map((resourceItem) => {
      let output = `
           <li class="resource-item col-12 col-md-6 ">
           <div class="resource-thumbnail">
              <img src="${resourceItem.thumbnailURL}" />
           </div>
           <div class="resource-info">
               <div><h4 class="resource-item-title">${resourceItem.title}</h4>
               <p class="resource-item-description">${resourceItem.description}</p></div>
               <a target="_blank" href="${resourceItem.resourceURL}"  class="resource-item-download-btn">Download</a>
           </div>
           </li>`;

      outputItems += output;
    });

    resourceList.innerHTML = outputItems;

    resourceSection.appendChild(resourceList);
    this.parent.appendChild(resourceSection);

    //Insert into submenu
    let resourcesSubmenuList = document.getElementById('resource-submenu-list');
    let outputListItem = document.createElement('li');
    outputListItem.setAttribute('section-id', sectionId);

    outputListItem.addEventListener('click', (e) => {
      let allResourceSubmenu = document.querySelectorAll(
        '#resource-submenu-list .list-item'
      );
      for (let i = 0; i < allResourceSubmenu.length; i++) {
        allResourceSubmenu[i].className = 'list-item';
      }
      outputListItem.className = 'list-item active';

      let targetSectionId = outputListItem.getAttribute('section-id');
      let activeSection = document.getElementById(targetSectionId);
      let allResourceSection = document.querySelectorAll('.resource-section');
      //Clear all active section
      for (let i = 0; i < allResourceSection.length; i++) {
        allResourceSection[i].className = 'resource-section container';
      }

      activeSection.className = 'resource-section active-section container';
    });
    if (isDefaultSection) {
      outputListItem.className = 'list-item active';
    } else {
      outputListItem.className = 'list-item';
    }

    let listItemText = document.createElement('span');
    listItemText.setAttribute('section-id', sectionId);
    listItemText.innerText = title;

    outputListItem.appendChild(listItemText);

    resourcesSubmenuList.appendChild(outputListItem);
  }
}

module.exports = Resources;
