const AbstractComponent = require('./AbstractComponent');

const browserDetect = require("../utils/browsers");

const {
  createElementWithAttribute,
} = require('../utils/functions');
class browserAlert extends AbstractComponent {
  constructor(elem) {
    super(elem);
    this.info = browserDetect.init();
  }

  isChrome() {
    return this.info.browser.name === "Chrome"
  }

  isMobile() {
    const mobile = ['Windows Phone', 'iPhone', 'Android']
    return mobile.includes(this.info.os.name)
  }

  init(isAlert) {
    let alertText;
    let alertDiv = createElementWithAttribute('div', {
      class: "alert alert-warning",
      role: "alert"
    })


    alertDiv.style.position = "absolute";
    alertDiv.style.top = "10px";

    // checking to display different alert
    if (this.isMobile()) {
      alertText = `You are using a mobile device. C3D requires a desktop browser. \nPlease log in from a computer.`
      isAlert && alert(alertText); //if login, display this alert. Otherwise, just warning

      alertDiv.innerHTML = alertText;
      this.elem.appendChild(alertDiv);
    } else if (!this.isChrome()) {
      alertText = `You are using ${this.info.browser.name}. C3D works best in Chrome. \nPlease switch your browser for optimal C3D experience.`
      isAlert && alert(alertText);

      alertDiv.innerHTML = alertText;
      this.elem.appendChild(alertDiv);
    }




  }


}

module.exports = browserAlert;
