const createElementWithAttribute = (elemName, attribute) => {
  const elem = document.createElement(elemName);
  for (const [key, value] of Object.entries(attribute)) {
    elem.setAttribute(key, value);
  }
  return elem;
};

const getDataFromFirebase = (url, uid) => {
  return new Promise((resolve, reject) => {
    database
      .ref('/profiles/' + uid + '/' + url)
      .once('value')
      .then((snapshot) => {
        let result = snapshot.val();
        if (result) {
          resolve({ data: result, status: 'OK' });
        } else {
          reject({ data: null, status: 'ERROR' });
        }
      });
  });
};

module.exports = { createElementWithAttribute, getDataFromFirebase };
